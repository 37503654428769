body:has(.CreateAccountForm) {
    @import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');
    --titleFontSize: 20px;
    .CreateAccountForm {
        height: 100%;
    }
    .dx-item.dx-box-item:has(input[name='acknowledgement.signature']) {
        align-content: flex-end;
        flex-wrap: wrap;
    }

    .dx-field-item-label-text,
    .dx-field-item-required-mark {
        color: var(--baseTextColor);
        font-size: 16px !important;
    }

    .dx-popup-content:has(#tooltip-content) {
        color: white;
        background-color: var(--BaseColor);
        border-radius: 3px;
        #tooltip-content {
            font-size: large;
            font-family: 'Karla', sans-serif;
            text-align: left;
        }
    }

    .dx-datagrid-edit-popup:has(.dx-box-item) {
        border-radius: 3px;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 2%;
        .dx-popup-normal {
            height: fit-content !important;
        }
        .dx-popup-content {
            height: fit-content !important;
        }
    }

    .ssn-lock {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5%;
        margin-right: 5%;
        width: 10%;
    }

    .dx-invalid-message-content {
        padding-left: unset !important;
    }

    .dx-list-item .dx-item-content.dx-list-item-content:first-letter {
        text-transform: uppercase;
    }

    .uploadContainer {
        visibility: hidden;
    }

    .dx-field-item-optional:has(.uploadContainer) {
        .dx-field-item-label.dx-field-item-label-location-top {
            visibility: hidden;
        }
    }
    .dx-scrollview-content:has(.register) {
        .dx-layout-manager .dx-field-item {
            font-size: 16px !important;
            font-weight: 600;
        }
        .dx-button-content .dx-button-text {
            font-family: 'Karla', sans-serif;
        }
        .dx-list-item-content {
            text-transform: capitalize;
        }
        .policy-info {
            font-family: 'Karla', sans-serif;
            font-weight: 400;
        }
        .dx-list-item-content::before {
            content: unset !important;
        }
        .dx-field-item-label-content {
            display: flex;
            font-family: 'Karla', sans-serif;
        }
        .dx-field-item-required-mark {
            color: black;
        }
        .dx-field-item-label-location-top {
            padding: 0px 0px 0px 6px;
        }
        .dx-item-content.dx-box-item-content.dx-box-flex.dx-box.dx-widget.dx-collection:has(.login-link) {
            flex-direction: column !important;
        }
        .login-link {
            justify-content: center;
            display: flex;
            a {
                margin-left: 5px;
            }
        }
        .dx-button:has(.dx-button-submit-input) {
            background-color: var(--BaseColor);
            color: var(--backgroundColor);
            border-radius: 4px;
            width: 50% !important;
            .dx-icon {
                color: white;
            }
        }
        .dx-first-col.dx-last-col.dx-field-item.dx-col-0.dx-field-button-item {
            text-align: center !important;
        }
        .add-button {
            background-color: var(--BaseColor);
            color: var(--backgroundColor);
            border-radius: 3%;
            .dx-icon {
                color: white;
            }
        }

        .dx-toolbar:has(.dx-button) {
            .dx-button {
                background-color: var(--BaseColor);
                color: var(--backgroundColor);
                border-radius: 3%;
            }
            .dx-icon {
                color: white;
            }
        }

        .profile-button {
            margin: 0px 0px 30px 0px;
            display: flex;
            .dx-button {
                width: 50%;
            }
            .dx-item-selected {
                background-color: var(--mainColor) !important;
            }
        }

        .dx-card:has(.register) {
            @media only screen and (min-width: 500px) {
                width: 70%;
                padding-bottom: unset;
            }
            @media only screen and (max-width: 500px) {
                width: 95%;
                height: 95%;
            }
        }

        .dx-tabpanel {
            &-tabs {
                padding: 0px 20px;
            }
            .dx-tabs {
                background-color: var(--backgroundColor) !important;
            }
        }

        .dx-tab {
            background-color: var(--backgroundColor);
            font-family: 'Karla', sans-serif;
            border-radius: 4px;
            &-text {
                color: var(--BaseColor) !important;
            }
            &.dx-tab-selected::before {
                background-color: var(--BaseColor);
            }
            &:hover {
                background-color: var(--backgroundColor);
            }
        }

        .profile-button .dx-item-selected {
            background-color: var(--BaseColor) !important;
        }

        .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item-selected.dx-button-normal {
            color: var(--backgroundColor) !important;
        }

        .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-item.dx-button-normal {
            color: var(--BaseColor);
        }

        // .dx-buttongroup-wrapper,
        // .dx-tabs-wrapper {
        //     border: 1px solid var(--BaseColor);
        //     border-radius: 4px;
        // }
        .dx-tabs {
            border: 1px solid var(--cardBorderColor);
            border-radius: 4px;
        }
        .dx-texteditor.dx-editor-outlined {
            box-shadow: inset 0 0 0 1px var(--cardBorderColor) !important;
            margin-bottom: 10px;
        }
        .dx-button.dx-button-default.dx-state-hover {
            background-color: var(--BaseColor);
        }

        .profile-button {
            margin: 0px 0px 10px 0px;
        }

        .dx-header-row {
            color: var(--baseTextColor);
        }
        .dx-field-item-label-content:has(#activities) {
            .dx-field-item-required-mark {
                color: white;
            }
        }
        .ssnInfo {
            display: flex;
            background-color: var(--infoSection);
            border-radius: 15px;
            margin-top: 20px;
            .ssnText {
                padding: 5%;
                font-weight: 400;
                font-family: 'Karla', sans-serif;
            }
        }

        .infoIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
            img {
                height: 25px;
            }
        }

        .dx-selectbox .dx-texteditor-input {
            text-transform: capitalize !important;
        }

        .dx-icon {
            color: gray;
            font-size: 14px;
        }

        .dx-fileuploader-input-wrapper {
            border: 1px solid var(--BaseColor);
            border-radius: 3px;
        }

        .dx-fileuploader-button {
            background-color: var(--BaseColor);
            color: var(--backgroundColor);
            margin-left: 5px;
        }

        .dx-field-button-item:has(.add-button) {
            text-align: center !important;
        }

        .uploadedImage {
            max-height: 150px;
        }

        .dx-field-item-label-text {
            color: var(--mainColor);
        }
        .dx-card {
            border-radius: var(--cardCornerRadius);
        }

        .header {
            display: inline-flex;
            flex-direction: row;
            width: 100%;
            .description {
                display: none;
            }
        }

        .app-logo-svg {
            width: -moz-fit-content;
            width: fit-content;
            height: fit-content;
        }

        .title {
            justify-content: flex-end;
            align-content: center;
            display: flex;
            flex-wrap: wrap;
        }

        .errorMessage {
            text-align: center;
        }

        .button-register {
            text-align: center !important;
            .dx-button {
                width: 65% !important;
            }
            &-back {
                text-align: center !important;
                .dx-button {
                    width: 65% !important;
                    &-content {
                        background-color: var(--secondaryColor);
                    }
                }
            }
        }
    }
}
