body:has(.change-password-form) {
    .dx-card {
        padding: unset !important;
    }
    .change-password-form{
        width: 60%;
        margin: auto;
    }
    .dx-field-button-item {
        text-align: center !important;
        .dx-button {
            width: 70% !important;
        }
    }
}