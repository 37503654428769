form {
    min-width: 80%;
    .form-text {
        margin: 10px 0;
        color: var(--baseTextColor);
    }

    .link {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        text-transform: capitalize;
        a {
            text-decoration: none;
        }
    }
    .dx-checkbox-text {
        text-transform: capitalize;
    }
    .dx-button {
        background-color: var(--BaseColor) !important;
        color: var(--backgroundColor) !important;
        .dx-button-text {
            text-transform: capitalize !important;
        }
        &-default {
            border-radius: var(--PrimaryButtonRadius);
            height: var(--PrimaryButtonHeight);
        }
        .dx-icon {
            color: white;
        }
    }

    .dx-invalid-message.dx-overlay {
        padding-bottom: 5px;
    }
    .dx-invalid-message > .dx-overlay-content {
        padding-top: 4px;
    }
    .dx-layout-manager .dx-field-item {
        padding-bottom: 14px;
    }

    .login-link {
        text-align: center;
        font-size: medium;
        font-weight: 600;
        a {
            margin-left: 5px;
        }
    }

    .dx-texteditor.dx-editor-filled::after,
    .dx-texteditor.dx-state-focused::before{
        border-bottom: none;
    }
    .dx-texteditor.dx-editor-filled {
        border-radius: 0.8em;
    }
    .dx-texteditor-with-floating-label.dx-state-focused {
        .dx-texteditor-label {
            color: gray !important;
        }
    }
    .dx-texteditor.dx-editor-filled {
        border: var(--InputBorder);
    }
    .dx-texteditor.dx-editor-filled.dx-state-hover{
        background: var(--textEditorInputColor);
    }
}
