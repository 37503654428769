.with-footer > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content {
    height: 100%;

    & > .dx-scrollview-content {
        display: flex;
        flex-direction: var(--CardDirection);
        min-height: 100%;
    }
}

html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    font-family: var(--FontFamily) !important;
    a {
        color: var(--HyperLinkColor) !important;
    }

    .dx-scrollview-content {
        justify-content: center;
    }

    .dx-checkbox-icon {
        width: 18px;
        height: 18px;
        border: 2px solid var(--BaseColor);
        border-radius: 2px;
    }
}

#root {
    height: 100%;
}

#PublicContent {
    height: 100%;
}
