@import '../../Styles.scss';
body:has(.PublicContentK33) {
    font-family: 'Manrope', sans-serif !important;
    .dx-widget {
        font-family: 'Manrope', sans-serif !important;
    }
    background: #1C1C1E;
    background-image: url("../../../../public/firms/K33/images/app-background.png");
    background-attachment: fixed; /* Keeps the background fixed in place */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    .errorMessage {
        color: #515252;
        margin: unset;
        font-size: smaller;
        svg {
            color: var(--errorColor);
            margin-right: 5px;
        }
    }
    .dx-invalid.dx-texteditor::after {
        border-top: 1px solid var(--errorColor);
        border-bottom: 1px solid var(--errorColor);
    }
    .two-factor-form,
    .forgot-password-form {
        .dx-form{
            width: 80%;
            margin: auto;
        }
    }
    #PublicContent:has(.TwoFA-Register) {
        div.header{
            margin-left: 10% !important;
        }
        .TwoFA-Register {
            width: 80%;
            margin: auto;

            .dx-field-item-content.dx-field-item-content-location-bottom {
                p {
                    word-wrap: break-word;
                }
            }
        }
    }
    #PublicContent:has(.forgot-password-form) {
        --titleFontSize: 24px;
        div.dx-card.content {
            height: unset !important;
            padding-left: unset !important;
            padding-right: unset !important;
        }
    }
    .primary-button {
        .dx-button.dx-button-has-text {
            background-color: var(--BaseColor) !important;
        }
    }
    .change-password-form {
        .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
            padding-top: 14px !important;
        }

        .dx-invalid-message > .dx-overlay-content {
            font-size: .65em;
        }
    }
    .cardFooterMessageContainer{
        text-align: center;
        .cardFooterMessage {
            font-size: xx-small;
            text-align: center;
            text-decoration: none;
        }
    }
    @media only screen and (min-width: 1000px) {
    background-position: -52% -116%;
    background-size: 69% 132%;
    .PublicContentK33 {
        display: flex;
    }
    .dx-button-text {
        color: var(--mainColor);
    }
    #PublicContent:has(.login-form),
    #PublicContent:has(.two-factor-form) {
        .dx-card.content {
            height: 350px !important;
        }
    }
    .dx-card.content {
        width: 400px !important;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        min-height: 314px !important;
        .header {
            margin-left: 10%;
            margin-right: 10%;
            .app-logo-svg {
                display: none;
            }
        }
        .title {
            margin-bottom: 10px !important;
            justify-content: start !important;
            font-weight: 700 !important;
            line-height: 20px !important;
        }
        .description {
            margin: unset !important;
            font-size: 16px !important;
            line-height: 20px !important;
        }
        form .link {
            a {
                text-decoration: underline !important;
                color: #515252 !important;
            }
        }
    }
    #PublicContent:has(.TwoFA) {
        --titleFontSize: 24px;
        .title {
            font-weight: 700;
        }
        .description {
            font-size: 14px;
            font-weight: 400;
        }
        .dx-card.content{
            padding-left: unset !important;
            padding-right: unset !important;
        }
    }
    #PublicContent:has(.TwoFA-QR) {
        .header {
            margin-left: 0% !important;
        }
    }
    .logo-container{
        margin-top: 21vh;
    }
    .app-logo-svg {
        justify-content: center;
        display: flex;
        div {
            filter: invert(1);
            width: 50%;
            display: flex;
            justify-content: center;
        }
    }
    .primary-button {
        .dx-button.dx-button-has-text {
            background-color: var(--BaseColor) !important;
        }
    }
    .secondary-button {
        .dx-button {
            background-color: var(--BaseColor) !important;
        }
        .dx-button-content {
            span {
                color: var(--mainColor) !important;
            }
            span:hover {
                color: var(--mainColor) !important;
            }
        }
        .dx-button-content:hover {
            span {
                color: var(--mainColor) !important;
            }
        }
    }
    .dx-scrollview-content:has(.dx-loadindicator) {
        .dx-card.content {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
    .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
        padding-top: 9px !important;
    }
    .dx-texteditor.dx-state-focused .dx-texteditor-label{
        color: rgb(128, 128, 128);
    }
    form .dx-invalid-message.dx-overlay{
        padding-bottom: unset !important;
    }
}
@media only screen and (max-width: 1000px) {
    background-position: 69% 320%;
    background-size: 150%;
    .PublicContentK33 {
        display: flex;
        flex-direction: column;
        .with-footer{
            width: 100% !important;
        }
        .dx-scrollable-container{
            overflow: hidden !important;
        }
    }
    .dx-button-text {
        color: var(--mainColor);
    }
    .with-footer:has(.outside-logo){
        height: 20% !important;
    }
    .with-footer:not(:has(.outside-logo)) {
        height: 70% !important;
    }
    .dx-card.content {
        width: 80% !important;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        min-height: 314px !important;
        height: unset !important;
        .header {
            padding-bottom: 15px !important;
            margin-left: 10%;
            margin-right: 10%;
                .app-logo-svg {
                    display: none;
                }
        }
        .title {
            margin-bottom: 10px !important;
            justify-content: start !important;
            font-weight: 700 !important;
            line-height: 20px !important;
        }
        .description {
            margin: unset !important;
            padding-top: 2px;
            font-size: 16px !important;
            line-height: 20px !important;
        }
        form .link {
            a {
                text-decoration: underline !important;
                color: #515252 !important;
            }
        }
    }
    .logo-container{
        margin-top: 0vh;
    }
    .outside-logo {
        margin-top: 10%;
        align-content: center;
    }
    .app-logo-svg {
        justify-content: center;
        display: flex;
        div {
            filter: invert(1);
            width: 30%;
            display: flex;
            justify-content: center;
            svg{
                height: 100%;
            }
        }
    }
    #PublicContent:has(.TwoFA) {
        --titleFontSize: 24px;
        .title {
            font-weight: 700;
        }
        .description {
            font-size: 14px;
            font-weight: 400;
        }
        .dx-card.content{
            min-height: 485px !important;
            padding-left: unset !important;
            padding-right: unset !important;
        }
        .with-footer:has(.outside-logo) {
            height: 13% !important;
        }
        .with-footer:not(:has(.outside-logo)) {
            height: 86% !important;
        }
        .outside-logo {
            margin-top: 8%;
        }
        .app-logo-svg {
            div {
                svg {
                    height: 70%;
                }
            }
        }
        .secondary-button {
            .dx-button-content {
                span {
                    padding: 6px 0px;
                }
            }
        }
    }

    #PublicContent:has(.TwoFA-QR) {
        .header {
            margin-left: 0% !important;
        }
    }
    
    .secondary-button {
        .dx-button {
            background-color: var(--BaseColor) !important;
        }
        .dx-button-content {
            span {
                color: var(--mainColor) !important;
            }
            span:hover {
                color: var(--mainColor) !important;
            }
        }
        .dx-button-content:hover {
            span {
                color: var(--mainColor) !important;
            }
        }
    }
    .dx-scrollview-content:has(.dx-loadindicator) {
        .dx-card.content {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
    .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
        padding-top: 10px !important;
    }
    .with-footer > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content > .dx-scrollview-content {
        min-height: unset;
    }
    .dx-texteditor.dx-state-focused .dx-texteditor-label{
        color: rgb(128, 128, 128);
    }
    form .dx-invalid-message.dx-overlay{
        padding-bottom: unset !important;
    }
    .dx-texteditor.dx-state-focused .dx-texteditor-label{
        color: rgb(128, 128, 128);
    }
    form .dx-invalid-message.dx-overlay{
        padding-bottom: unset !important;
    }
}
}

