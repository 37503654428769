.dx-scrollview-content:has(.login-form) {
    .title {
        font-size: 32px;
        margin-bottom: unset;
    }
    @media (max-width: 500px) {
        --screenWidth: 95%;
        .login-link {
            &-hide{
                display: none;
            }
            &-show{
                display: block;
            }
        }
    }
    @media (min-width: 501px) {
        --screenWidth: 400px;
    }
    .dx-card {
        padding: unset;
        width: var(--screenWidth);
        min-height: var(--CardMinHeight) !important;
    }

    .login-form {
        width: 60%;
        margin: auto;
    }
    .dx-field-item-label-location-top {
        display: none !important;
    }
}
