@import '../../Styles.scss';

body:has(.PublicContentCoinhub) {
    font-family: 'Manrope', sans-serif !important;

    .dx-widget {
        font-family: 'Manrope', sans-serif !important;
    }

    background: linear-gradient(180deg, rgba(15, 40, 75, 0.90) 40.14%, rgba(11, 75, 115, 0.90) 100%);
    background-attachment: fixed;
    /* Keeps the background fixed in place */
    background-repeat: no-repeat;

    .dx-loadindicator-segment-inner {
        border-color: var(--backgroundColor) var(--backgroundColor) transparent !important;
    }

    /* Prevents the image from repeating */
    .errorMessage {
        color: #515252;
        margin: unset;
        font-size: smaller;

        svg {
            color: var(--errorColor);
            margin-right: 5px;
        }
    }

    .dx-invalid.dx-texteditor::after {
        border-top: 1px solid var(--errorColor);
        border-bottom: 1px solid var(--errorColor);
    }

    .two-factor-form,
    .forgot-password-form {
        .dx-form {
            width: 85%;
            margin: auto;
        }
    }


    #PublicContent:has(.TwoFA-Register) {
        div.header {
            margin-left: 10% !important;
        }

        .TwoFA-Register {
            width: 80%;
            margin: auto;
        }
    }

    #PublicContent:has(.forgot-password-form) {
        --titleFontSize: 24px;

        div.dx-card.content {
            width: 400px !important;
            padding-left: unset !important;
            padding-right: unset !important;


            .header {
                margin: 0 7% 30px 7%;

                .app-logo-svg {
                    display: none;
                }
            }

            .title {
                margin-bottom: 10px !important;
                justify-content: start !important;
                font-weight: 800;
                letter-spacing: -0.32px;
            }

            .description {
                font-size: 14px !important;
                margin: unset !important;
                font-weight: 800;
                letter-spacing: -0.32px;
            }
        }

        .dx-field-button-item .dx-button {
            width: 100% !important;
        }

        .login-link {
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            color: #8E8E93 !important;

            a {
                color: #8E8E93 !important;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-decoration-skip-ink: none;
                text-decoration-thickness: auto;
                text-underline-offset: auto;
                text-underline-position: from-font;
            }
        }
    }

    .primary-button {
        .dx-button.dx-button-has-text {
            background-color: var(--BaseColor) !important;
        }
    }

    .change-password-form {
        .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
            padding-top: 14px !important;
        }

        .dx-invalid-message>.dx-overlay-content {
            font-size: .65em;
        }
    }

    .cardFooterMessageContainer {
        text-align: center;

        .cardFooterMessage {
            font-size: xx-small;
            text-align: center;
            text-decoration: none;
        }
    }

    @media only screen and (min-width: 1000px) {
        background-position: -52% -116%;
        background-size: 100% 100%;

        .PublicContentCoinhub {
            display: flex;
        }

        .dx-button-text {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: var(--mainColor);
        }

        #PublicContent:has(.login-form),
        #PublicContent:has(.two-factor-form) {
            .dx-card.content {
                height: 350px !important;
                width: 400px !important;
                padding-top: 40px !important;
                padding-bottom: 40px !important;
                min-height: 314px !important;

                .header {
                    margin: 0 7% 30px 7%;

                    .app-logo-svg {
                        display: none;
                    }
                }

                .title {
                    margin-bottom: 10px !important;
                    justify-content: start !important;
                    font-weight: 800;
                    letter-spacing: -0.32px;
                }

                .description {
                    font-size: 14px !important;
                    margin: unset !important;
                    font-weight: 800;
                    letter-spacing: -0.32px;
                }

                form .link {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;

                    a {
                        color: #8E8E93 !important;
                    }
                }

                form .link.login-link-show {
                    font-size: 14px;
                    font-weight: 800;
                    line-height: 24px;

                    span.text {
                        color: #8E8E93 !important;
                        font-weight: 600;
                        margin-right: 5px;
                    }
                }

                .login-form {
                    width: 85% !important;
                }

                form .login-link {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #8E8E93 !important;

                    a {
                        color: #8E8E93 !important;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 800;
                        text-decoration: none;
                    }
                }
            }

        }

        #PublicContent:has(.two-factor-form) {
            .dx-card.content {
                height: 250px !important;
                width: 400px !important;
                min-height: 260px !important;
            }
        }

        #PublicContent:has(.TwoFA) {
            --titleFontSize: 24px;

            .title {
                margin-bottom: 10px !important;
                justify-content: start !important;
                font-weight: 800;
                letter-spacing: -0.32px;
            }

            .description {
                font-size: 14px !important;
                margin: unset !important;
                font-weight: 800;
                letter-spacing: -0.32px;
            }

            .dx-card.content {
                padding-left: unset !important;
                padding-right: unset !important;

                .header {
                    margin: 0 7% 30px 7%;

                    .app-logo-svg {
                        display: none;
                    }
                }
            }
        }

        #PublicContent:has(.TwoFA-QR) {
            .header {
                margin-left: 0% !important;
            }
        }

        .logo-container {
            margin-top: 27.2vh;
        }

        .app-logo-svg {
            justify-content: center;
            display: flex;

            div {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        .primary-button {
            .dx-button.dx-button-has-text {
                background-color: var(--BaseColor) !important;
            }
        }

        .secondary-button {
            .dx-button {
                background-color: var(--BaseColor) !important;
            }

            .dx-button-content {
                span {
                    color: var(--mainColor) !important;
                }

                span:hover {
                    color: var(--mainColor) !important;
                }
            }

            .dx-button-content:hover {
                span {
                    color: var(--mainColor) !important;
                }
            }
        }

        .dx-scrollview-content:has(.dx-loadindicator) {
            .dx-card.content {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
        }

        .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
            padding-top: 10px !important;
        }

        .dx-texteditor.dx-state-focused .dx-texteditor-label {
            color: rgb(128, 128, 128);
        }

        form .dx-invalid-message.dx-overlay {
            padding-bottom: unset !important;
        }

        form .dx-texteditor.dx-editor-filled {
            border-radius: 4px !important;
        }

        .CreateAccountForm {
            --titleFontSize: 24px;
            display: flex;
            height: 100%;
            width: 100%;


            .dx-card.content {
                overflow: auto;

                .header {
                    margin: 0;

                    .app-logo-svg {
                        display: none;
                    }
                }

                .title {
                    margin-bottom: 10px !important;
                    justify-content: start !important;
                    font-weight: 800 !important;
                    letter-spacing: -0.32px;
                }

                .register {
                    .dx-tabpanel-tabs {
                        padding: 0 !important;
                    }

                    .dx-tab {
                        font-family: 'Manrope', sans-serif !important;
                    }

                    .dx-tab-text {
                        font-size: 14px;
                        font-weight: 800;
                        line-height: 30px;
                        letter-spacing: -0.2px;
                        color: var(--tabTextColor) !important;
                    }

                    .dx-field-item-label-content {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16px;
                        font-family: 'Manrope', sans-serif !important;
                        padding-bottom: 10px;
                    }

                    .dx-field-item-required-mark {
                        color: #007AFF;
                    }

                    .dx-field-item-label-location-top {
                        padding: 0;
                    }

                    .login-link {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        color: #8E8E93 !important;

                        a {
                            color: #8E8E93 !important;
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 800;
                            text-decoration: none;
                        }
                    }

                    .dx-box-flex.dx-box.dx-widget.dx-collection {
                        width: 672px !important;
                    }
                }

                .register:has(.reviewDescription) {
                    height: 55em;

                    .reviewDescription {
                        font-size: 14px !important;
                        font-weight: 800;
                    }
                }

                .dx-item-content.dx-multiview-item-content {
                    padding: 20px 0 20px 0;
                }

                span.dx-button-text {
                    font-family: "Manrope", sans-serif;
                }
            }

            .single-card {
                .dx-card {
                    input {
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        background-position: 69% 320%;
        background-size: 100%;

        .PublicContentCoinhub {
            display: flex;
            flex-direction: column;

            .with-footer {
                width: 100% !important;
            }

            .dx-scrollable-container {
                overflow: hidden !important;
            }
        }

        .dx-button-text {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: var(--mainColor);
        }

        .with-footer:has(.outside-logo) {
            height: 20% !important;
        }

        .with-footer:not(:has(.outside-logo)) {
            height: 70% !important;
        }

        .dx-card.content {
            width: 90% !important;
            padding-top: 20px !important;
            padding-bottom: 20px !important;
            min-height: 314px !important;
            height: unset !important;

            .header {
                padding-bottom: 15px !important;
                margin-left: 10%;
                margin-right: 10%;

                .app-logo-svg {
                    display: none;
                }
            }

            .title {
                margin-bottom: 10px !important;
                justify-content: start !important;
                font-weight: 800 !important;
                letter-spacing: -0.32px;
            }

            .description {
                margin: unset !important;
                padding-top: 2px;
                font-weight: 800;
                line-height: 20px !important;
                letter-spacing: -0.2px;
            }

            form .link {
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;

                a {
                    color: #8E8E93 !important;
                }
            }

            form .link.login-link-show {
                font-size: 16px;
                font-weight: 800;
                line-height: 24px;

                span.text {
                    color: #8E8E93 !important;
                    font-weight: 600;
                    margin-right: 5px;
                }
            }
        }

        .logo-container {
            margin-top: 0vh;
        }

        .outside-logo {
            margin-top: 10%;
            align-content: center;
        }

        .app-logo-svg {
            justify-content: center;
            display: flex;

            div {
                width: 30%;
                display: flex;
                justify-content: center;

                svg {
                    height: 100%;
                }
            }
        }

        #PublicContent:has(.login-form),
        #PublicContent:has(.two-factor-form) {
            .dx-card.content {
                height: 350px !important;
                width: 400px !important;
                padding-top: 40px !important;
                padding-bottom: 40px !important;
                min-height: 314px !important;

                .header {
                    margin: 0 7% 30px 7%;

                    .app-logo-svg {
                        display: none;
                    }
                }

                .title {
                    margin-bottom: 10px !important;
                    justify-content: start !important;
                    font-weight: 800;
                    letter-spacing: -0.32px;
                }

                .description {
                    font-size: 14px !important;
                    margin: unset !important;
                    font-weight: 800;
                    letter-spacing: -0.32px;
                }

                form .link {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16px;

                    a {
                        color: #8E8E93 !important;
                    }
                }

                form .link.login-link-show {
                    font-size: 14px;
                    font-weight: 800;
                    line-height: 24px;

                    span.text {
                        color: #8E8E93 !important;
                        font-weight: 600;
                        margin-right: 5px;
                    }
                }

                .login-form {
                    width: 85% !important;
                }

                form .login-link {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #8E8E93 !important;

                    a {
                        color: #8E8E93 !important;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 800;
                        text-decoration: none;
                    }
                }
            }

        }


        #PublicContent:has(.two-factor-form) {
            .dx-card.content {
                height: 250px !important;
                width: 400px !important;
                min-height: 280px !important;
            }
        }

        #PublicContent:has(.TwoFA) {
            --titleFontSize: 24px;

            .title {
                margin-bottom: 10px !important;
                justify-content: start !important;
                font-weight: 800;
                letter-spacing: -0.32px;
            }

            .description {
                font-size: 14px !important;
                margin: unset !important;
                font-weight: 800;
                letter-spacing: -0.32px;
            }

            .dx-card.content {
                padding-left: unset !important;
                padding-right: unset !important;
            }
        }

        #PublicContent:has(.TwoFA-QR) {
            .header {
                margin-left: 0% !important;
            }
        }

        #PublicContent:has(.TwoFA-Register) {
            .TwoFA-Register {
                .dx-field-item-content.dx-field-item-content-location-bottom {
                    p {
                        word-wrap: break-word;
                    }
                }
            }
        }

        .secondary-button {
            .dx-button {
                background-color: var(--BaseColor) !important;
            }

            .dx-button-content {
                span {
                    color: var(--mainColor) !important;
                }

                span:hover {
                    color: var(--mainColor) !important;
                }
            }

            .dx-button-content:hover {
                span {
                    color: var(--mainColor) !important;
                }
            }
        }

        .dx-scrollview-content:has(.dx-loadindicator) {
            .dx-card.content {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
        }

        .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
            padding-top: 10px !important;
        }

        .with-footer>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content>.dx-scrollview-content {
            min-height: unset;
        }

        .dx-texteditor.dx-state-focused .dx-texteditor-label {
            color: rgb(128, 128, 128);
        }

        form .dx-invalid-message.dx-overlay {
            padding-bottom: unset !important;
        }

        .dx-texteditor.dx-state-focused .dx-texteditor-label {
            color: rgb(128, 128, 128);
        }

        form .dx-invalid-message.dx-overlay {
            padding-bottom: unset !important;
        }

        form .dx-texteditor.dx-editor-filled {
            border-radius: 4px !important;
        }

        .CreateAccountForm {
            --titleFontSize: 24px;
            display: block;
            height: 100%;
            width: 100%;


            .dx-card.content {
                overflow: auto;

                .header {
                    margin: 0;

                    .app-logo-svg {
                        display: none;
                    }
                }

                .title {
                    margin-bottom: 10px !important;
                    justify-content: start !important;
                    font-weight: 800 !important;
                    letter-spacing: -0.32px;
                }

                .register {
                    .dx-tabpanel-tabs {
                        padding: 0 !important;
                    }

                    .dx-tab {
                        font-family: 'Manrope', sans-serif !important;
                    }

                    .dx-tab-text {
                        font-size: 14px;
                        font-weight: 800;
                        line-height: 30px;
                        letter-spacing: -0.2px;
                        color: var(--tabTextColor) !important;
                    }

                    .dx-field-item-label-content {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16px;
                        font-family: 'Manrope', sans-serif !important;
                        padding-bottom: 10px;
                    }

                    .dx-field-item-required-mark {
                        color: #007AFF;
                    }

                    .dx-field-item-label-location-top {
                        padding: 0;
                    }

                    .login-link {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 24px;
                        color: #8E8E93 !important;

                        a {
                            color: #8E8E93 !important;
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 800;
                            text-decoration: none;
                        }
                    }

                    .dx-box-flex.dx-box.dx-widget.dx-collection {
                        width: 362.688px !important;
                    }
                }

                .register:has(.reviewDescription) {
                    height: 55em;

                    .reviewDescription {
                        font-size: 14px !important;
                        font-weight: 800;
                    }
                }

                .dx-item-content.dx-multiview-item-content {
                    padding: 20px 0 20px 0;
                }

                span.dx-button-text {
                    font-family: "Manrope", sans-serif;
                }
            }

            .single-card {
                .dx-card {
                    input {
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .dx-card.content {
            width: 90% !important;
            height: 100vh !important;
            padding: 40px 1em;
        }

        .CreateAccountForm {
            .dx-card.content {
                overflow: auto;

                .register {
                    .dx-box-flex.dx-box.dx-widget.dx-collection {
                        width: 100% !important;
                    }

                    .dx-tab {
                        padding: 12px 12px
                    }
                }

                .register:has(.reviewDescription) {
                    height: 100%;
                }
            }
        }
    }
}